<template>
  <div class="add-vehicle">
    <confirmation-dialog
      @close="dialogDelete = false"
      :dialog="dialogDelete"
      @confirm="deleteCarParts()"
    />

    <part-dialog
      @close="dialog = false"
      :dialog="dialog"
      :type="partType"
      :partId="selectedPartId"
      :partNumber="selectedPartNumber"
      :partName="selectedPartName"
    />


    <div class="page-header">
      <h1>{{ $route.name }}</h1>
      <div class="add-btn">
        <button class="btn custom-btn" @click="backToListing()">
          {{ $t("commonLayout.backToListing") }}
        </button>
      </div>
    </div>
    <div class="hint">
      <span class="hint--red">Search or look for part name and click <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.256"
                        height="16.256"
                        viewBox="0 0 16.256 16.256"
                      >
                        <g transform="translate(-8.45 -8.55)">
                          <path
                            d="M22.326,10.93a8.129,8.129,0,1,0,0,11.5A8.133,8.133,0,0,0,22.326,10.93ZM20.27,17.138a.684.684,0,0,1-.47.2H17.254V19.88a.669.669,0,0,1-.666.666.66.66,0,0,1-.666-.666V17.334H13.376a.666.666,0,0,1,0-1.332h2.546V13.456a.66.66,0,0,1,.666-.666h0a.66.66,0,0,1,.666.666V16H19.8a.66.66,0,0,1,.666.666A.786.786,0,0,1,20.27,17.138Z"
                            transform="translate(0 0)"
                          />
                        </g>
                      </svg> icon to add it into inventory. </span>
    </div>
    
    <div class="custom-table">
      <table
        id="example"
        class="display responsive nowrap"
        cellspacing="0"
        width="100%"
      >
        <thead>
        <tr>
          <th class="desktop tablet-l tablet-p mobile-p mobile-l">Id</th>
          <th class="desktop tablet-l tablet-p mobile-p mobile-l">Part Name</th>
          <th class="desktop tablet-l tablet-p mobile-p mobile-l">Actions</th>
        </tr>
        </thead>
      </table>
    </div>
  </div>
</template>
<script>
import {debounce} from "lodash";
import {
  required,
  between,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import {showSuccess, showFailure} from "../eventbus/action.js";
import {mapActions, mapGetters} from "vuex";
import $ from "jquery";
import {PART_LIST} from "@/constants/apiUrls";
import tokenService from "@/services/token.service";
import moment from "moment";
import ConfirmationDialog from "../components/ConfirmationDialog";
import PartDialog from "@/components/PartDialog";
import EventBus from "@/eventbus";

export default {
  components: {
    ConfirmationDialog,
    PartDialog
  },
  data() {
    return {
      selectedPartId: '',
      selectedPartNumber: '',
      selectedPartName: '',
      partType: 'PartType',
      dialogDelete: false,
      dialog: false,
      items: [],
      filter: "year",
      image: {},
      initialize: true,
      partsOption: [],
      data: {
        part_name: null,
        part_name_code: null,
      },
      item: {
        value: "",
        text: "",
      },
      vinLoading: false,
      imageResponseArray: [],
      gradeOptions: [
        {text: this.$t("commonLayout.select"), value: null},
        {text: "A", value: "A"},
        {text: "B", value: "B"},
        {text: "C", value: "C"},
      ],
      warrantyOptions: [
        {text: this.$t("commonLayout.select"), value: null},
        {text: "AS IS", value: "AS IS"},
        {text: "5 "+this.$t("commonLayout.days"), value: "5 "+this.$t("commonLayout.days")},
        {text: "15 "+this.$t("commonLayout.days"), value: "15 "+this.$t("commonLayout.days")},
        {text: "30 "+this.$t("commonLayout.days"), value: "30 "+this.$t("commonLayout.days")},
        {text: "60 "+this.$t("commonLayout.days"), value: "60 "+this.$t("commonLayout.days")},
        {text: "90 "+this.$t("commonLayout.days"), value: "90 "+this.$t("commonLayout.days")},
      ],
      selected: [], // Must be an array reference!
      options: [
        {text: "ID", value: "id"},
        {text: "Part Name", value: "name"},
      ],
      makeList: [],
      modelList: [],
      yearList: [],
      trimList: [],
      inputs: [
        {
          file: "",
        },
      ],
      files_url: "",
      count: 1,
      country: 'us'
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      partsList: "partlist/getPartNames",
      carDetailData: "carlist/getCarDetail",
      // imageResponse: "partlist/getImageResponse",
      mediaImageResponse: "partlist/getMediaImageResponse",
      partsData: "partlist/getPartById",
    }),
  },
  watch: {
    "$i18n.locale": function(nVal, oVal) {
      if(nVal != oVal) {
        this.gradeOptions = [
          {text: this.$t("commonLayout.select"), value: null},
          {text: "A", value: "A"},
          {text: "B", value: "B"},
          {text: "C", value: "C"},
        ],
        this.warrantyOptions = [
          {text: this.$t("commonLayout.select"), value: null},
          {text: "AS IS", value: "AS IS"},
          {text: "5 "+this.$t("commonLayout.days"), value: "5 "+this.$t("commonLayout.days")},
          {text: "15 "+this.$t("commonLayout.days"), value: "15 "+this.$t("commonLayout.days")},
          {text: "30 "+this.$t("commonLayout.days"), value: "30 "+this.$t("commonLayout.days")},
          {text: "60 "+this.$t("commonLayout.days"), value: "60 "+this.$t("commonLayout.days")},
          {text: "90 "+this.$t("commonLayout.days"), value: "90 "+this.$t("commonLayout.days")},
        ];
      }
    }
  },
  validations: {
    data: {
      part_name: {required},
    },
  },
  destroyed() {
    $("li.parts-wrapper > a").removeClass(
      "router-link-exact-active router-link-active"
    );
  },
  async mounted() {
    this.country = await this.user?.user_detail?.country || 'us';
    await this.getPartList();

    EventBus.$on("resetDialog", () => {
      this.dialog = false;
      this.selectedPartId = '';
      this.selectedPartNumber = '';
      this.selectedPartName = '';
      this.partType = 'PartType';
    });

    EventBus.$on("getParts", () => {
      this.table.ajax.reload();
    });
    await this.addListeners();
    this.initialize = true;
    if (this.$route.params.uid) {
      await this.fetchVehiclePartInfo(this.$route.params.uid);
    }

    // if (this.$route.params.vid) {
    //   this.getCarDetails();
    // }
    let self = this;
    $("#input-group-6 input").bind("keyup", function (e) {
      self.filterParts();
    });
    setTimeout(() => {
      $("li.parts-wrapper > a").addClass(
        "router-link-exact-active router-link-active"
      );
    }, 10);
    window.scrollTo({top: 0, behavior: "smooth"});
  },
  methods: {
    ...mapActions({
      // uploadImage: "partlist/uploadImage",
      uploadMediaImage: "partlist/uploadMediaImage",
      addParts: "partlist/addParts",
      partsById: "partlist/getPartsById",
      carDetail: "carlist/getCarDetail",
      updateVehicleParts: "partlist/updateVehicleParts",
      addDirectParts: "partlist/addDirectParts",
      deleteCarPart: "partlist/deleteCarPart",
    }),

    filterParts: debounce(function () {
      this.getCarNameDetails();
    }, 1500),

    validateForm() {
      this.$v.data.$touch();
      if (!this.$v.data.$invalid) {
        return true;
      } else {
        return false;
      }
    },

    async addListeners() {
      let self = this;

      $("#example").on("click", ".edit-icon", function (e) {
        let id = e.currentTarget.dataset.secret;
        let part_number = e.currentTarget.dataset.part_number;
        let part_name = e.currentTarget.dataset.part_name;
        self.selectedPartId = id;
        self.selectedPartNumber = part_number;
        self.selectedPartName = part_name;
        self.partType = '';
        self.dialog = true;
        e.preventDefault();
      });

      $("#example").on("click", ".add-icon", function (e) {
        let id = e.currentTarget.dataset.secret;
        let part_number = e.currentTarget.dataset.part_number;
        let part_name = e.currentTarget.dataset.part_name;
        self.selectedPartId = id;
        self.selectedPartNumber = part_number;
        self.selectedPartName = part_name;
        self.partType = 'PartType';
        self.dialog = true;
        e.preventDefault();
      });

      $("#example").on("click", ".delete-icon", function (e) {
        self.selectedId = e.currentTarget.dataset.secret;
        self.dialogDelete = true;
        e.preventDefault();
      });
    },

    reload() {
      try {
        this.$store.commit("setLoader", true);
        this.table.ajax
          .url(
            process.env.VUE_APP_API_HOST +
            PART_LIST +
            `?search[key]=${this.filter}`
          )
          .load();
        $.fn.dataTable.ext.errMode = "throw";
      } catch ({message}) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async getPartList() {
      let self = this;
      try {
        this.$store.commit("setLoader", true);
        let carProfileId = this.$route.params.vid ? this.$route.params.vid : this.$route.params.uid;

        this.table = $("#example").DataTable({
          ajax: {
            url:
              process.env.VUE_APP_API_HOST +
              PART_LIST +
              `?search[key]=${this.filter}&carProfileId=${carProfileId}`,
            type: "GET",
            beforeSend: function (request) {
              request.setRequestHeader(
                "Authorization",
                `Bearer ${tokenService.getAccessToken()}`
              );
            },
          },
          columns: [
            {
              orderable: false,
              data: function (full) {
                return full.id || "N/A";
              },
            },
            {
              orderable: false,
              data: function (full) {
                return (full.name || "N/A") + (full?.car_part?.checkout_at ? '  <span class="hint--red">(Part has been checked Out on '+full?.car_part?.checkout_at+'. Click Edit Button to add it again)</span>' : '');
              },
            },
            {
              orderable: false,
              data: function (row) {
                if (!row.car_part) {
                  return `<ul class="action-list">
                  <li id="add-icon">
                    <a
                      data-secret = ${row.id}
                      data-part_number = ${row.id}
                      data-part_name= "${row.name}"
                      class="act-icon add-icon tooltips"
                      data-tooltip="Add Parts"
                      :to="{ name: 'Add Part', params: { id: item.id } }"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.256"
                        height="16.256"
                        viewBox="0 0 16.256 16.256"
                      >
                        <g transform="translate(-8.45 -8.55)">
                          <path
                            d="M22.326,10.93a8.129,8.129,0,1,0,0,11.5A8.133,8.133,0,0,0,22.326,10.93ZM20.27,17.138a.684.684,0,0,1-.47.2H17.254V19.88a.669.669,0,0,1-.666.666.66.66,0,0,1-.666-.666V17.334H13.376a.666.666,0,0,1,0-1.332h2.546V13.456a.66.66,0,0,1,.666-.666h0a.66.66,0,0,1,.666.666V16H19.8a.66.66,0,0,1,.666.666A.786.786,0,0,1,20.27,17.138Z"
                            transform="translate(0 0)"
                          />
                        </g>
                      </svg>
                    </a>
                  </li>
                </ul>
                `;
                } else if(row.car_part && row.car_part.checkout_at != null) {
                  return `<ul class="action-list">
                  <li id="edit-icon">
                    <router-link
                      data-secret = ${row.car_part.id}
                      data-part_number = ${row.car_part.part_number}
                      data-part_name = "${row.car_part.part_name}"
                      class="act-icon edit-icon tooltips"
                      data-tooltip="Add Again"
                      :to="{ name: 'Add Part', params: { uid: item.id } }"
                      style="background: rgb(240 173 78 / 15%);"
                    >
                    <svg
                        style="fill: rgb(240 173 78);"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.256"
                        height="16.256"
                        viewBox="0 0 16.256 16.256"
                      >
                        <g transform="translate(-8.45 -8.55)">
                          <path
                            d="M22.326,10.93a8.129,8.129,0,1,0,0,11.5A8.133,8.133,0,0,0,22.326,10.93ZM20.27,17.138a.684.684,0,0,1-.47.2H17.254V19.88a.669.669,0,0,1-.666.666.66.66,0,0,1-.666-.666V17.334H13.376a.666.666,0,0,1,0-1.332h2.546V13.456a.66.66,0,0,1,.666-.666h0a.66.66,0,0,1,.666.666V16H19.8a.66.66,0,0,1,.666.666A.786.786,0,0,1,20.27,17.138Z"
                            transform="translate(0 0)"
                          />
                        </g>
                      </svg>
                    </router-link>
                  </li>
                </ul>
                `;
                } else {
                  return `<ul class="action-list">
                  <li id="edit-icon">
                    <router-link
                      data-secret = ${row.car_part.id}
                      data-part_number = ${row.car_part.part_number}
                      data-part_name = "${row.car_part.part_name}"
                      class="act-icon edit-icon tooltips"
                      data-tooltip="Edit"
                      :to="{ name: 'Add Part', params: { uid: item.id } }"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.258"
                        height="16.256"
                        viewBox="0 0 16.258 16.256"
                      >
                        <g transform="translate(-2.468 -2.525)">
                          <g transform="translate(2.468 2.525)">
                            <path
                              d="M22.116,16.3,13.8,24.6a12.685,12.685,0,0,1,2.019,1.643,12.127,12.127,0,0,1,1.643,2.019l8.316-8.316a10,10,0,0,0-1.608-2.036A10.025,10.025,0,0,0,22.116,16.3Z"
                              transform="translate(-11.861 -13.943)"
                            />
                            <path
                              d="M72.454,4.54,70.863,2.948a1.456,1.456,0,0,0-2.053,0L67.8,3.958A12.686,12.686,0,0,1,69.819,5.6,11.967,11.967,0,0,1,71.462,7.62l1.01-1.01A1.493,1.493,0,0,0,72.454,4.54Z"
                              transform="translate(-56.621 -2.525)"
                            />
                            <path
                              d="M3.654,70.8,2.491,74.684a.536.536,0,0,0,.667.667l3.884-1.163a10.417,10.417,0,0,0-1.506-1.882A9.711,9.711,0,0,0,3.654,70.8Z"
                              transform="translate(-2.468 -59.118)"
                            />
                          </g>
                        </g>
                      </svg>
                    </router-link>
                  </li>
                  <li>
                  <a
                    @click="
                      selectedItem = item.id;
                      deleteItemConfirm();
                    "
                    data-secret = ${row.car_part.id}
                    class="act-icon delete-icon tooltips"
                    data-tooltip="Delete"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30.002"
                      viewBox="0 0 30 30.002"
                    >
                      <g transform="translate(0 0)">
                        <path
                          d="M28.877,28.9a3.732,3.732,0,0,1-5.291,0L1.094,6.395A3.744,3.744,0,0,1,6.385,1.1L28.877,23.6a3.748,3.748,0,0,1,0,5.3ZM28.9,6.395,6.419,28.9a3.743,3.743,0,1,1-5.291-5.295L23.612,1.1A3.743,3.743,0,1,1,28.9,6.395Z"
                          transform="translate(0 0)"
                        />
                      </g>
                    </svg>
                  </a>
                </li>
                </ul>
                `;
                }
              },
            },
          ],
          paging:   false,
          serverSide: true,
          processing: false,
          dom: "Bfrtip",
          buttons: ["copy", "csv", "print"],
        });
        $.fn.dataTable.ext.errMode = "throw";
      } catch ({message}) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    deleteImage(type) {
      $(`#${type}`).empty();
      $(`#${type}-delIcon`).hide();
      delete this.image[type];
    },

    async saveImage(file, tag) {
      try {
        this.$store.commit("setLoader", true);
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        await formData.append("company_name", this.user.user_detail.company_name);
        // await this.uploadImage(formData);
        await this.uploadMediaImage(formData);
        showSuccess("Image Saved Successfully");
        // return this.imageResponse;
        return this.mediaImageResponse;
        // this.data.media_ids = this.filelist.map((val) => val.data.id);
      } catch ({message}) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async getCarDetails() {
      try {
        this.$store.commit("setLoader", true);
        await this.carDetail(this.$route.params.vid);
        this.data.miles = this.carDetailData.miles;
      } catch ({message}) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async getCarNameDetails() {
      try {
        const val = $("#input-group-6 input").val();
        this.$store.commit("setLoader", true);
        await this.getPartName({search: val});
        this.partsOption = this.partsList.map(function (val) {
          return {
            value: val.name,
            text: val.name,
          };
        });
      } catch ({message}) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async getPartDetails() {
      try {
        this.$store.commit("setLoader", true);
        await this.partDetails(this.$route.params.id);
      } catch ({message}) {
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async onFileChange(e, type) {
      $(`#${type}`).empty();
      $(`#${type}`).append(`<img class=${type} src='' alt=''>`);
      const file = e.target.files[0];
      // this.left = URL.createObjectURL(file);
      this.image[type] = await this.saveImage(file, type);

      $(`.${type}`).attr("src", this.image[type].data.url);
      $(`#${type}-delIcon`).show();
    },

    backToListing(){this.$router.push({ name: "Car list" });},

    changeRouter() {
      if (this.$route.name === "Add Vehicle Part") {
        this.$router.push({name: "Car list"});
      } else if (this.$route.name === "Update Vehicle Part") {
        this.$router.push({
          name: "Car Detail",
          params: {id: this.partsData.car_profile_id},
        });
      } else if (
        this.$route.name === "Edit Part" ||
        this.$route.name === "Add Parts"
      ) {
        this.$router.push({name: "Part list"});
      }
    },

    async save() {
      this.imageResponseArray = [];
      Object.keys(this.image).forEach((val) => {
        this.imageResponseArray.push(this.image[val]);
      });
      this.$v.data.$touch();
      if (!this.$v.data.$invalid) {
        try {
          this.$store.commit("setLoader", true);
          let payload = Object.assign({}, this.data);
          payload.country = this.country;
          payload.car_profile_id = this.$route.params.vid;
          payload.is_on_facebook = this.selected.some(
            (val) => val === "is_on_facebook"
          );
          payload.is_on_ebay = this.selected.some(
            (val) => val === "is_on_ebay"
          );
          payload.is_on_instagram = this.selected.some(
            (val) => val === "is_on_instagram"
          );
          payload.media_ids = this.imageResponseArray.map((val) => val.data.id);
          if (this.$route.params.uid) {
            payload.car_profile_id = this.partsData.car_profile_id;
            await this.updateVehicleParts({
              ...payload,
            });
            showSuccess("Part Updated Successfully");
          }
          if (this.$route.params.vid) {
            await this.addParts({
              ...payload,
            });
            showSuccess("Part Added Successfully");
          }
          if (this.$route.name === "Add Parts") {
            await this.addDirectParts({
              ...payload,
            });
            showSuccess("Part Added Successfully");
          }
          this.changeRouter();
        } catch ({message}) {
          const errorMessage =
            typeof message === "object"
              ? Object.values(message)[0][0]
              : message;
          showFailure(errorMessage);
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },
    async deleteCarParts() {
      try {
        this.$store.commit("setLoader", true);
        await this.deleteCarPart(this.selectedId);
        this.table.ajax.reload();
        this.dialogDelete = false;
        showSuccess("Car Parts deleted Successfully");
      } catch ({message}) {
        console.log(message);
        const errorMessage =
          typeof message === "object" ? Object.values(message)[0][0] : message;
        showFailure(errorMessage);
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>

